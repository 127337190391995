const Landscape = () => {
    return (
        <div id="landscape" className="flex_center">
            <div className="flex_center">
                <img src="images/ic_phone.svg" />
                Rangoli Beats is best viewed in portrait mode.
            </div>
        </div>
    );
};

export default Landscape;
