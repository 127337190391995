import axios from "axios";

export const getToken = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}user/token`
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

export const updateUser = async (formData) => {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}user/user`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};
