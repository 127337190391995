const Header = () => {
  return (
    <header>
      <img
        src="/images/rangoli_beats_logo.png"
        width={100}
        // onClick={() =>
        //   window.open("https://indiafirstlife.com", "_blank").focus()
        // }
      />
      <img
        src="/images/new_logo.png"
        width={80}
        onClick={() =>
          window.open("https://indiafirstlife.com", "_blank").focus()
        }
      />
    </header>
  );
};

export default Header;
