import { useState } from "react";

const Footer = ({
    creationId,
    activeTab,
    setActiveTab,
    rangoliData,
    selectedRangoli,
    chosenColor,
    onRangoliSelect,
    onColorSelect,
}) => {
    return (
        <footer>
            {!creationId && (
                <>
                    {activeTab === 0 && (
                        <div className="tabs_content">
                            <ul id="rangolies">
                                {rangoliData.map((item) => {
                                    return (
                                        <li key={item._id}>
                                            <a
                                                href="#"
                                                className={`${
                                                    selectedRangoli._id ===
                                                    item._id
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    onRangoliSelect(item)
                                                }
                                            >
                                                <img
                                                    src={`/svg/${item.name}.svg`}
                                                    width="40"
                                                />
                                            </a>
                                        </li>
                                    );
                                })}
                                {/* <li>
                            <a href="#">
                                <img
                                    src="/images/design_01_thumb.png"
                                    width="32"
                                />
                            </a>
                        </li> */}
                            </ul>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div className="tabs_content">
                            <ul id="colors">
                                {selectedRangoli.tunes?.map((tune, index) => {
                                    return (
                                        <li key={index}>
                                            <a
                                                href="#"
                                                style={{
                                                    backgroundColor: tune.color,
                                                }}
                                                className={
                                                    chosenColor.color ===
                                                    tune.color
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    onColorSelect(tune)
                                                }
                                            >
                                                <span>&nbsp;</span>
                                            </a>
                                        </li>
                                    );
                                })}
                                {/* <li>
                            <a href="#" className="color_01 active">
                                <span>&nbsp;</span>
                            </a>
                        </li> */}
                            </ul>
                        </div>
                    )}
                    <div className="footer_tabs">
                        <a
                            href="#"
                            className={`rangoli_link ${
                                activeTab === 0 && "active"
                            }`}
                            onClick={() => setActiveTab(0)}
                        >
                            <label className="design_thumb">&nbsp;</label>
                            <br />
                            Rangoli
                        </a>
                        <a
                            href="#"
                            className={`rangoli_link ${
                                activeTab === 1 && "active"
                            }`}
                            onClick={() => setActiveTab(1)}
                        >
                            <span>&nbsp;</span>
                            <br />
                            Colors
                        </a>
                    </div>
                </>
            )}
        </footer>
    );
};

export default Footer;
