import axios from "axios";

export const fetchRangoliData = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}creation/rangolidata`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

export const saveCreation = async (formData) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}creation/creation`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return response.data;
    } catch (err) {
        console.log(err);
        return err.response.data;
    }
};

export const fetchCreationById = async (creationId) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}creation/creation/${creationId}`
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

export const exportVideo = async (creationId) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}creation/download/${creationId}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

export const downloadVideo = async (creationId) => {
    const response = await fetch(
        "https://indiafirst-rangoli-beats.s3.ap-south-1.amazonaws.com/creations/635210323a41f143b0ce9da1.mp4"
    );
    const blobData = await response.blob();
    // const response = await axios.get(
    //     "https://indiafirst-rangoli-beats.s3.ap-south-1.amazonaws.com/creations/635210323a41f143b0ce9da1.mp4"
    // );

    return blobData;
};
