const QRScanner = () => {
    return (
        <div id="qr_scanner" className="flex_center">
            <div className="flex_center">
                This microsite is only available for mobile version,
                <br />
                scan this QR code to access the site.
                <img src="images/qr_code.svg" width="180" className="ms-2" />
            </div>
        </div>
    );
};

export default QRScanner;
