const HelpModal = ({ onClose }) => {
    return (
        <div id="popup">
            <div id="popup_inner" className="popup_steps">
                <div id="popup_form">
                    <div className="steplist">
                        <table width="100%">
                            <tr>
                                <td width="52">STEP 1 :</td>
                                <td>
                                    <img src="/images/select_rangoli.svg" />
                                    Select your rangoli
                                </td>
                            </tr>
                            <tr>
                                <td>STEP 2 :</td>
                                <td>
                                    <img src="/images/click_icon.svg" />
                                    Click on Colours and colour your musical
                                    rangoli
                                </td>
                            </tr>
                            <tr>
                                <td>STEP 3 :</td>
                                <td>
                                    <img src="/images/play_edit_icon.svg" />
                                    Play/Edit as required
                                </td>
                            </tr>
                            <tr>
                                <td>STEP 4 :</td>
                                <td>
                                    <img src="/images/download_icon.svg" />
                                    Once done, save the rangoli
                                </td>
                            </tr>
                            <tr>
                                <td>STEP 5 :</td>
                                <td>
                                    <img src="/images/share_download_icon.svg" />
                                    Share your rangoli
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <a href="#" className="close_popup" onClick={onClose}>
                    &nbsp;
                </a>
            </div>
        </div>
    );
};

export default HelpModal;
