import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { saveCreation } from "../../actions/creation";

// ✨Happy Diwali!✨
// I made this Rangoli Beat to wish my most favourite people, like you! 😊
// I hope this musical rangoli brings the festive cheer and good fortune to you and your family.
// If you want to make one for your favourites, click here:

const ShareMessage = `
Happy musical Diwali and a vibrant New Year
✨Helloooo✨
I made this Rangoli Beat to wish my most favourite people, like you! 😊
I hope this musical rangoli brings good vibes and fortune to you and your family.
If you want to make one for your favourites, click here:

`;

const ShareModal = (props) => {
  const url = window.location.href;
  // const url = "https://google.com";

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Share</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-start">
        <div className="d-flex justify-content-between mb-3">
          <FacebookShareButton quote={ShareMessage} url={url}>
            <FacebookIcon round size={40} />
          </FacebookShareButton>
          {/* <FacebookMessengerShareButton
                        title={ShareMessage}
                        url={url}
                    >
                        <FacebookMessengerIcon round size={40} />
                    </FacebookMessengerShareButton> */}
          <WhatsappShareButton title={ShareMessage} url={url}>
            <WhatsappIcon round size={40} />
          </WhatsappShareButton>
          {/* <InstapaperShareButton title={ShareMessage} url={url}>
                        <InstapaperIcon round size={40} />
                    </InstapaperShareButton> */}
          <LinkedinShareButton
            title={ShareMessage}
            summary={ShareMessage}
            url={url}
          >
            <LinkedinIcon round size={40} />
          </LinkedinShareButton>
          <TwitterShareButton title={ShareMessage} url={url}>
            <TwitterIcon round size={40} />
          </TwitterShareButton>
          <EmailShareButton
            subject="Happy Diwali"
            body={ShareMessage}
            url={url}
          >
            <EmailIcon round size={40} />
          </EmailShareButton>
        </div>
        {/* <p>Or Copy Message</p>
                <div>
                    <div ref={messageRef} className="form-control"></div>
                    <Button
                        className="form-control mt-2"
                        onClick={() => {
                            toast.success("Message copied");
                            navigator.clipboard.writeText(
                                messageRef.current.innerHTML
                            );
                        }}
                    >
                        Copy Message
                    </Button>
                </div> */}
      </Modal.Body>
      {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
    </Modal>
  );
};

export default ShareModal;
