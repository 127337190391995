import { useFormik } from "formik";
import { updateUser } from "../../actions/auth";

const SaveModal = ({ onClose }) => {
    const saveFormik = useFormik({
        initialValues: {
            name: "",
            mob: "",
            email: "",
        },
        onSubmit: async (values) => {
            const response = await updateUser(values);
            if (response.status) {
                localStorage.setItem("name", saveFormik.values.name);
                onClose(true);
            }
        },
    });

    return (
        <div id="popup">
            <div id="popup_inner">
                <div id="popup_form">
                    <p>
                        We hope this Rangoli Beat brings joy and melody to you
                        and to those dear to you.
                    </p>
                    {/* <p>
                        Waise hee life ke certainties ki planning rakhegi
                        <br />
                        aapko secure
                    </p>
                    <p>aur aap rahoge chill!</p> */}

                    <form onSubmit={saveFormik.handleSubmit}>
                        <div className="form_control">
                            <label className="text-start">Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                value={saveFormik.values.name}
                                onChange={saveFormik.handleChange}
                                required
                            />
                        </div>
                        <div className="form_control">
                            <label className="text-start">Phone Number</label>
                            <input
                                type="text"
                                name="mob"
                                placeholder="Enter Phone"
                                value={saveFormik.values.mob}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        saveFormik.handleChange(e);
                                    }
                                }}
                            />
                        </div>
                        <div className="form_control">
                            <label className="text-start">Email ID</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter Email"
                                value={saveFormik.values.email}
                                onChange={saveFormik.handleChange}
                                required
                            />
                        </div>
                        <div className="form_control txc">
                            <input type="submit" value="Download Now" />
                        </div>
                    </form>

                    {/* <p className="bigger">
                        We hope this Rangoli Beat brings joy, along with the
                        melody, to your life and of those dear to you.
                    </p> */}
                </div>
                <a
                    href="#"
                    className="close_popup"
                    onClick={() => onClose(false)}
                >
                    &nbsp;
                </a>
            </div>
        </div>
    );
};

export default SaveModal;
