import { useEffect, useRef, useState } from "react";
import useStateRef from "react-usestateref";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
// import { ReactComponent as Cheshire } from "./assets/svg/rangoli_2_1.svg";
import $ from "jquery";
import { getToken } from "./actions/auth";
import ShareModal from "./components/ShareModal/ShareModal";
import { Share } from "@capacitor/share";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import {
    downloadVideo,
    exportVideo,
    fetchCreationById,
    fetchRangoliData,
    saveCreation,
} from "./actions/creation";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import { isMobile } from "react-device-detect";
import QRScanner from "./components/QRScanner/QRScanner";
import Landscape from "./components/Lanscape/Landscape";
import ReactHowler from "react-howler";
import SaveModal from "./components/SaveModal/SaveModal";
import HelpModal from "./components/HelpModal/HelpModal";
import download from "downloadjs";
import ReactGA from "react-ga";

ReactGA.initialize("G-N544137D2H");

// ✨Happy Diwali!✨
// I made this Rangoli Beat to wish my most favourite people, like you! 😊
// I hope this musical rangoli brings the festive cheer and good fortune to you and your family.
// If you want to make one for your favourites, click here:
const ShareMessage = `
Happy musical Diwali and a vibrant New Year
✨Helloooo✨
I made this Rangoli Beat to wish my most favourite people, like you! 😊
I hope this musical rangoli brings good vibes and fortune to you and your family.
If you want to make one for your favourites, click here:

`;

function App() {
    const soundPath = "/sounds/";
    const params = useParams();
    const navigate = useNavigate();

    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [rangoliData, setRangoliData] = useState(null);
    const [selectedRangoli, setSelectedRangoli] = useState(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [svgColor, setSvgColor, svgColorRef] = useStateRef(null);
    const [colorTimeline, setColorTimeline, colorTimelineRef] = useStateRef([]);
    const [chosenColor, setChosenColor, chosenColorRef] = useStateRef({
        color: "#CECCCC",
        tune: `${soundPath}Tone-Gen-Sitar2-A.mp3`,
    });
    const [rangoliSvg, setRangoliSvg, rangoliSvgRef] = useStateRef(null);
    const [isPlaying, setIsPlaying, isPlayingRef] = useStateRef(false);
    const [isShared, setIsShared, isSharedRef] = useStateRef(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [saveModalTrigger, setSaveModalTrigger] = useState("");
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    let sound;
    const fillSpeed = 0.15;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

        setTimeout(() => {
            setShowSplashScreen(false);
        }, 4000);
    }, []);

    useEffect(() => {
        if ("webkitAudioContext" in window) {
            alert("webcontext init");
            new webkitAudioContext();
        }

        if (!showSplashScreen) {
            loadInitData();
        }
    }, [showSplashScreen]);

    useEffect(() => {
        $(svgColorRef.current).off("click");
        $(svgColorRef.current).on("click", colorMe);

        if (params.creationId) {
            colorTimelineRef.current.forEach((item) => {
                const path = $(`path#${item.name}`);
                TweenMax.to(path, 1, {
                    fill: item.color,
                });
            });
        }
    }, [svgColor]);

    useEffect(() => {
        const loadSvg = async () => {
            switch (selectedRangoli.name) {
                case "Rangoli_1":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_1.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_2":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_2.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_3":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_3.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_4":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_4.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_5":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_5.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_6":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_6.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_7":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_7.svg`)
                        ).ReactComponent
                    );
                    break;
                case "Rangoli_8":
                    setRangoliSvg(
                        await (
                            await import(`./assets/svg/Rangoli_8.svg`)
                        ).ReactComponent
                    );
                    break;
                default:
                    break;
            }
        };

        if (selectedRangoli) {
            if (!params.creationId) {
                setColorTimeline([]);
            }
            setIsPlaying(false);
            if (selectedRangoli.tunes) {
                setChosenColor(selectedRangoli.tunes[0]);
            }
            loadSvg();
        }
    }, [selectedRangoli]);

    useEffect(() => {
        initApp();
    }, [rangoliSvg]);

    async function loadInitData() {
        const checkToken = async () => {
            const response = await getToken();
            if (response.status) {
                localStorage.setItem("token", response.data.token);
            }
        };

        const loadRangoliData = async () => {
            const response = await fetchRangoliData();
            if (response.status) {
                setRangoliData(response.data);
                if (response.data.length > 0) {
                    setSelectedRangoli(response.data[0]);
                    setChosenColor(response.data[0].tunes[0]);
                }
            }
        };

        const loadCreationData = async () => {
            const response = await fetchCreationById(params.creationId);
            if (response.status) {
                setActiveTab(1);
                setSelectedRangoli(response.data[0].rangoli);
                // setChosenColor(response.data[0].tunes[0]);
                // svgClear();
                setColorTimeline(response.data[0].creationData);
            }
        };

        if (!localStorage.getItem("token")) {
            await checkToken();
        }

        if (params.creationId) {
            await loadCreationData();
        } else {
            await loadRangoliData();
        }
    }

    function initApp() {
        const svgObject = $("svg");
        setSvgColor($("g#Color", svgObject).find("path"));
    }

    function colorMe() {
        if (
            $(this).attr("fill") === "black" ||
            $(this).attr("fill") === "#000000"
        ) {
            return;
        }

        if (isPlayingRef.current) {
            // toast.error("Cannot edit rangoli while playing!");
            return;
        }

        if (params.creationId || isSharedRef.current) {
            return;
        }

        if (activeTab === 0) {
            setActiveTab(1);
        }

        sound = new Howl({
            src:
                process.env.REACT_APP_S3_ASSET_URL +
                chosenColorRef.current.tune,
            volume: 0.8,
            autoUnlock: true,
            onplayerror: function () {
                sound.once("unlock", function () {
                    sound.play();
                });
            },
        });
        sound.play();
        TweenMax.to(this, fillSpeed, { fill: chosenColorRef.current.color });

        const existingPathIndex = colorTimelineRef.current.findIndex(
            (item) => item.name === this.id
        );
        if (existingPathIndex > -1) {
            colorTimelineRef.current[existingPathIndex].color =
                chosenColorRef.current.color;
            colorTimelineRef.current[existingPathIndex].tune =
                chosenColorRef.current.tune;
            // colorTimelineRef.current[existingPathIndex].svg = updatedSvg;
        } else {
            setColorTimeline((prev) => [
                ...prev,
                {
                    name: this.id,
                    tune: chosenColorRef.current.tune,
                    color: chosenColorRef.current.color,
                    // svg: updatedSvg,
                },
            ]);
        }
    }

    function play_audio(e, index = 0) {
        if (!isPlayingRef.current) {
            return;
        }

        if (index === 0) {
            svgClear();
            sound?.stop();
        }

        if (colorTimelineRef.current.length > 0) {
            sound = new Howl({
                src:
                    process.env.REACT_APP_S3_ASSET_URL +
                    colorTimelineRef.current[index].tune,
                volume: 0.8,
                autoUnlock: true,
                onplayerror: function () {
                    sound.once("unlock", function () {
                        sound.play();
                    });
                },
                onplay: function () {
                    const path = $(
                        `path#${colorTimelineRef.current[index].name}`
                    );
                    TweenMax.to(path, 1, {
                        fill: colorTimelineRef.current[index].color,
                    });

                    // start next sound after 500 miliseconds
                    setTimeout(() => {
                        index++;
                        if (index < colorTimeline.length) {
                            play_audio(e, index);
                        } else if (index === colorTimeline.length) {
                            // restart playback after all tunes done
                            setTimeout(() => {
                                play_audio(e);
                            }, 2000);
                        }
                    }, selectedRangoli.overlayTime);
                },
                onend: async function () {
                    // index++;
                    // if (index < colorTimelineRef.current.length) {
                    //     play_audio(e, index);
                    // } else if (index === colorTimelineRef.current.length) {
                    //     setTimeout(() => {
                    //         play_audio();
                    //     }, 2000);
                    // }
                },
            });

            sound.play();
        }
    }

    function stop_playback() {
        if (sound) {
            sound.stop();
        }

        colorTimeline.forEach((item) => {
            const path = $(`#${item.name}`);
            TweenMax.to(path, fillSpeed, { fill: item.color });
        });
    }

    function svgClear() {
        $(colorTimeline).each(function () {
            const path = $(`#${this.name}`);
            TweenMax.to(path, fillSpeed, { fill: "#ffffff" });
        });
    }

    function onReset() {
        setIsPlaying(false);
        if (!params.creationId) {
            svgClear();
            setColorTimeline([]);
            if (sound) {
                sound.stop();
            }
        } else {
            navigate("/");
            window.location.reload();
        }
    }

    async function onSaveCreation() {
        ReactGA.event({
            category: "Save",
            action: "click_save",
        });

        if (isPlaying) {
            sound?.stop();
            setIsPlaying(false);
        }
        const response = await saveCreation({
            creationData: colorTimeline,
            rangoliId: selectedRangoli._id,
        });
        if (response.status) {
            setIsShared(true);
            navigate(`/${response.data.id}`);
        }
    }

    async function handleDownload() {
        if (isDownloading) {
            return;
        }

        setIsDownloading(true);
        const toastId = toast.loading("Exporting video...");

        // const url =
        //     "https://indiafirst-rangoli-beats.s3.ap-south-1.amazonaws.com/creations/635210323a41f143b0ce9da1.mp4";

        // const response = await downloadVideo();
        // console.log(response);
        // download(response, "Rangoli.mp4", "video/mp4");

        const exportResponse = await exportVideo(params.creationId);
        if (exportResponse.status) {
            toast.dismiss(toastId);
            setIsDownloading(false);
            fetch(
                process.env.REACT_APP_S3_ASSET_URL +
                    "/" +
                    exportResponse.data.url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/blob",
                    },
                }
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    let fileName = (Math.random() + 1)
                        .toString(36)
                        .substring(7);
                    link.setAttribute(
                        "download",
                        `RangoliBeats${fileName}.mp4`
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    toast.success("Downloading video...");
                });
        }
    }

    return (
        <>
            <Toaster />
            <Header />
            <DeviceOrientation lockOrientation={"portrait"}>
                <Orientation orientation="portrait" alwaysRender={false}>
                    {(rangoliData || selectedRangoli) &&
                    isMobile &&
                    !showSplashScreen ? (
                        <>
                            <main>
                                {colorTimeline.length > 0 && (
                                    <div className="reset">
                                        <a href="#" onClick={onReset}>
                                            {params.creationId &&
                                            !isSharedRef.current
                                                ? "Create New"
                                                : "Reset"}
                                        </a>
                                    </div>
                                )}
                                {!params.creationId && (
                                    <div className="help-btn">
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setShowHelpModal(true);
                                            }}
                                        >
                                            Help
                                        </a>
                                    </div>
                                )}
                                <div className="rangoli">
                                    <div
                                        className={`rangoli_inside ${
                                            showSplashScreen
                                                ? "background-none"
                                                : ""
                                        }`}
                                    >
                                        <div className="held" id="ActivityDIV">
                                            {/* <Cheshire /> */}
                                            {rangoliSvgRef.current ? (
                                                <rangoliSvgRef.current />
                                            ) : (
                                                "loading"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </main>
                            <div className="controlls">
                                {activeTab === 1 && (
                                    <div className="buttons">
                                        {!params.creationId && (
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    if (isPlayingRef.current) {
                                                        setIsPlaying(false);
                                                        stop_playback();
                                                    }
                                                }}
                                            >
                                                <img
                                                    src="images/edit.svg"
                                                    className={`${
                                                        !isPlaying
                                                            ? "invert-btn"
                                                            : ""
                                                    }`}
                                                />
                                                <br />
                                                Edit
                                            </a>
                                        )}
                                        {params.creationId &&
                                            isSharedRef.current && (
                                                <>
                                                    <a
                                                        href="#"
                                                        onClick={async () => {
                                                            ReactGA.event({
                                                                category:
                                                                    "Share",
                                                                action: "click_share",
                                                            });

                                                            await Share.share({
                                                                title: "Happy Diwali",
                                                                text: ShareMessage,
                                                                url: window
                                                                    .location
                                                                    .href,
                                                            });
                                                            // if (
                                                            //     localStorage.getItem(
                                                            //         "name"
                                                            //     )
                                                            // ) {
                                                            //     // setShareModalOpen(
                                                            //     //     true
                                                            //     // );
                                                            // } else {
                                                            //     setShowSaveModal(
                                                            //         true
                                                            //     );
                                                            //     setSaveModalTrigger(
                                                            //         "share"
                                                            //     );
                                                            // }
                                                        }}
                                                    >
                                                        <img src="/images/share.svg" />
                                                        <br />
                                                        Share
                                                    </a>
                                                    <ShareModal
                                                        show={shareModalOpen}
                                                        onHide={() =>
                                                            setShareModalOpen(
                                                                false
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                        <a
                                            href="#"
                                            className="play"
                                            onClick={(e) => {
                                                if (!isPlaying) {
                                                    ReactGA.event({
                                                        category: "Play",
                                                        action: "click_play",
                                                    });
                                                    setIsPlaying(true);
                                                    play_audio(e);
                                                } else {
                                                    ReactGA.event({
                                                        category: "Stop",
                                                        action: "click_stop",
                                                    });
                                                    setIsPlaying(false);
                                                    stop_playback();
                                                }
                                            }}
                                        >
                                            <img
                                                src={`${
                                                    colorTimeline.length === 0
                                                        ? "images/icon_stop.svg"
                                                        : !isPlaying
                                                        ? "images/icon_play.svg"
                                                        : "images/icon_stop.svg"
                                                }`}
                                            />
                                            <br />
                                            {colorTimeline.length === 0
                                                ? "Stop"
                                                : !isPlaying
                                                ? "Play"
                                                : "Stop"}
                                        </a>
                                        {(!params.creationId &&
                                            !isSharedRef.current) ||
                                        (params.creationId &&
                                            isSharedRef.current) ? (
                                            <>
                                                <a
                                                    href="#"
                                                    onClick={() => {
                                                        if (
                                                            colorTimelineRef
                                                                .current
                                                                .length < 1
                                                        ) {
                                                            toast.error(
                                                                "There is nothing to save"
                                                            );
                                                            return;
                                                        }

                                                        if (
                                                            !params.creationId
                                                        ) {
                                                            onSaveCreation();
                                                            return;
                                                        } else {
                                                            return;
                                                        }

                                                        if (
                                                            isSharedRef.current &&
                                                            !localStorage.getItem(
                                                                "name"
                                                            )
                                                        ) {
                                                            setShowSaveModal(
                                                                true
                                                            );
                                                            return;
                                                        } else {
                                                            handleDownload();
                                                            return;
                                                        }
                                                    }}
                                                >
                                                    <img src="images/download.svg" />
                                                    <br />
                                                    {/* {isSharedRef.current
                                                        ? "Download"
                                                        : "Save"} */}
                                                    Save
                                                </a>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!params.creationId && (
                                <Footer
                                    creationId={params.creationId}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    rangoliData={rangoliData}
                                    selectedRangoli={selectedRangoli}
                                    chosenColor={chosenColor}
                                    onRangoliSelect={(e) =>
                                        setSelectedRangoli(e)
                                    }
                                    onColorSelect={(e) => {
                                        setChosenColor(e);
                                        sound = new Howl({
                                            src:
                                                process.env
                                                    .REACT_APP_S3_ASSET_URL +
                                                e.tune,
                                            volume: 0.8,
                                            autoUnlock: true,
                                            onplayerror: function () {
                                                sound.once(
                                                    "unlock",
                                                    function () {
                                                        sound.play();
                                                    }
                                                );
                                            },
                                        });
                                        sound.play();
                                    }}
                                />
                            )}
                            {showSaveModal && (
                                <SaveModal
                                    onClose={async (e) => {
                                        setShowSaveModal(false);
                                        if (e) {
                                            if (saveModalTrigger === "share") {
                                                ReactGA.event({
                                                    category: "Share",
                                                    action: "click_share",
                                                });

                                                await Share.share({
                                                    title: "Happy Diwali",
                                                    text: ShareMessage,
                                                    url: window.location.href,
                                                });
                                            } else {
                                                handleDownload();
                                            }
                                        }
                                    }}
                                />
                            )}
                            {showHelpModal && (
                                <HelpModal
                                    onClose={() => setShowHelpModal(false)}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {!isMobile ? (
                                <QRScanner />
                            ) : showSplashScreen ? (
                                <SplashScreen
                                    creationId={params.creationId}
                                    onClick={() => {
                                        setShowSplashScreen(false);
                                    }}
                                />
                            ) : (
                                "Loading..."
                            )}
                        </>
                    )}
                </Orientation>
                <Orientation orientation="landscape" alwaysRender={false}>
                    {!isMobile ? <QRScanner /> : <Landscape />}
                </Orientation>
            </DeviceOrientation>

            {/* <div className="holder">
                <div className="myTitle">SVG Coloring</div>
                <div className="held" id="ActivityDIV">
                    <Cheshire />
                </div>
                <div className="held">
                    <a id="btnRandom" className="button gray">Random Color</a>
                    {!params.creationId && (
                        <a
                            id="btnClear"
                            className="button gray"
                            onClick={() => {
                                svgClear();
                                if (sound) {
                                    setColorTimeline([]);
                                    // colorTimeline = [];
                                    sound.stop();
                                }
                            }}
                        >
                            Clear Color
                        </a>
                    )}
                    <a id="btnDownloadSVG" className="button gray">Download SVG</a>
                    <a
                        id="btnPlay"
                        className="button gray"
                        onClick={play_audio}
                    >
                        Play
                    </a>
                    {!params.creationId && (
                        <a
                            id="btnShare"
                            className="button gray"
                            onClick={() => setShareModalOpen(true)}
                        >
                            Share
                        </a>
                    )}
                    <ShareModal
                        show={shareModalOpen}
                        onHide={() => setShareModalOpen(false)}
                        colorTimeline={colorTimeline}
                    />
                </div>
            </div> */}
        </>
    );
}

export default App;
