const SplashScreen = ({ creationId, onClick }) => {
  return (
    <div onClick={onClick}>
      {/* <Header /> */}
      {/* <img
                src={
                    creationId
                        ? "images/share_splash.jpeg"
                        : "images/landing_banner.jpg"
                }
                width="100%"
                style={{ position: "absolute", zIndex: "1000" }}
            /> */}
      <div id="start_screen">
        <img
          src={creationId ? "images/text-2.png" : "images/text-1.png"}
          className="text-start"
          width="100%"
        />
        {!creationId && <a href="#">START</a>}
        <img src="images/deepak.png" className="deepak" width="60%" />
      </div>
    </div>
  );
};

export default SplashScreen;
